import React from "react";

const Blob = () => {
  return (
    <svg
      width="314"
      height="299"
      viewBox="0 0 314 299"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.6245 22.0084C50.9164 26.5938 11.4429 24.4008 2.27225 39.951C-6.89838 55.5012 14.2339 88.5952 20.4141 118.101C26.5943 147.606 17.8224 173.722 21.0122 199.44C24.202 225.158 39.5528 250.676 62.0806 268.618C84.6085 286.76 114.313 297.327 144.417 298.124C174.72 298.921 205.222 289.751 227.152 271.41C249.082 253.068 262.239 225.357 278.786 198.842C295.134 172.327 314.871 147.008 313.675 122.287C312.479 97.7658 290.549 73.8424 270.214 54.1056C249.68 34.3689 230.94 19.018 210.206 9.8474C189.473 0.676781 166.745 -2.11428 146.411 2.07231C126.076 6.2589 108.332 17.4231 79.6245 22.0084Z"
        fill="var(--primaryColor)"
      />
    </svg>
  );
};

export default Blob;
