import React from "react";

export const ContactImageBlob = () => {
  return (
    <svg
    width= "100%"
    height= "498.4px"
      viewBox="0 0 586 623"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M148.729 45.6516C95.1065 55.2377 21.3753 50.6531 4.24585 83.1623C-12.8836 115.672 26.5886 184.858 38.1324 246.542C49.6761 308.227 33.2914 362.826 39.2496 416.591C45.2076 470.356 73.8809 523.705 115.96 561.216C158.039 599.143 213.523 621.233 269.753 622.9C326.354 624.567 383.329 605.395 424.29 567.051C465.252 528.706 489.829 470.773 520.737 415.341C551.272 359.908 588.137 306.976 585.903 255.295C583.669 204.03 542.707 154.016 504.724 112.754C466.369 71.4923 431.366 39.3998 392.638 20.2277C353.911 1.05556 311.459 -4.77945 273.476 3.97305C235.494 12.7256 202.352 36.0656 148.729 45.6516Z"
        fill="var(--blobColor)"
      />
    </svg>
  );
};