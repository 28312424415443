import React from "react";

const Logo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 160 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.695 42.9066C23.4658 43.0332 24.1715 43.3481 24.8084 43.8547C25.449 44.3614 25.9557 44.9911 26.3284 45.7438C26.7048 46.4929 26.893 47.2927 26.893 48.1468C26.893 49.2234 26.6179 50.1933 26.0678 51.0564C25.5214 51.9214 24.7252 52.6017 23.6793 53.0975C22.6371 53.5951 21.403 53.843 19.9808 53.843H12.0625V32.6143H19.6768C21.1189 32.6143 22.3476 32.8531 23.3609 33.3308C24.3778 33.8085 25.1414 34.4527 25.648 35.2633C26.1547 36.074 26.408 36.9896 26.408 38.0065C26.408 39.2659 26.0678 40.3081 25.3875 41.1333C24.7071 41.9548 23.8096 42.5447 22.695 42.9066ZM14.8346 41.7847H19.4958C20.7932 41.7847 21.7975 41.4807 22.5068 40.8727C23.2197 40.2611 23.578 39.4161 23.578 38.3394C23.578 37.2646 23.2197 36.4214 22.5068 35.8134C21.7975 35.2054 20.7715 34.9014 19.4307 34.9014H14.8346V41.7847ZM19.7347 51.5559C21.1189 51.5559 22.1956 51.2338 22.9628 50.586C23.7336 49.9346 24.1208 49.0298 24.1208 47.8718C24.1208 46.6956 23.7155 45.7655 22.9049 45.0852C22.0942 44.4048 21.0085 44.0646 19.6478 44.0646H14.8346V51.5559H19.7347Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M45.0998 37.1523L35.0536 61.7033H32.1874L35.4734 53.662L28.7422 37.1523H31.8255L37.0585 50.6727L42.2409 37.1523H45.0998Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M51.2527 39.4397V49.276C51.2527 50.0866 51.4227 50.662 51.7665 50.9986C52.114 51.3315 52.7147 51.498 53.5688 51.498H55.6099V53.8431H53.1128C51.5675 53.8431 50.4094 53.4884 49.6386 52.7791C48.866 52.0662 48.4805 50.8973 48.4805 49.276V39.4397H46.3164V37.1525H48.4805V32.9473H51.2527V37.1525H55.6099V39.4397H51.2527Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M74.1854 44.8608C74.1854 45.3874 74.1529 45.9447 74.0913 46.5328H60.7519C60.8532 48.1794 61.4142 49.4642 62.4383 50.3906C63.4661 51.3134 64.711 51.773 66.1731 51.773C67.3746 51.773 68.3752 51.4944 69.1768 50.9335C69.9766 50.3743 70.5393 49.6288 70.8632 48.6969H73.8525C73.4037 50.3001 72.5081 51.6048 71.1672 52.6126C69.83 53.6169 68.1653 54.1181 66.1731 54.1181C64.5898 54.1181 63.173 53.7635 61.9244 53.0542C60.6795 52.3412 59.6988 51.3297 58.9858 50.0215C58.2765 48.7096 57.9219 47.1915 57.9219 45.4688C57.9219 43.7426 58.2657 42.2299 58.9569 40.9307C59.6463 39.6278 60.6162 38.6272 61.8665 37.9269C63.1151 37.2285 64.5518 36.8774 66.1731 36.8774C67.76 36.8774 69.1623 37.2231 70.3783 37.9125C71.5943 38.6037 72.5316 39.5537 73.1938 40.7642C73.8543 41.9711 74.1854 43.3373 74.1854 44.8608ZM71.3192 44.2818C71.3192 43.2251 71.084 42.3167 70.6172 41.5531C70.1539 40.7913 69.5188 40.2123 68.7136 39.816C67.912 39.4216 67.0235 39.2225 66.05 39.2225C64.6495 39.2225 63.4589 39.6695 62.4745 40.5615C61.4902 41.4554 60.9256 42.6949 60.7808 44.2818H71.3192Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M96.6012 42.9066C97.3721 43.0332 98.0778 43.3481 98.7147 43.8547C99.3553 44.3614 99.8619 44.9911 100.235 45.7438C100.611 46.4929 100.799 47.2927 100.799 48.1468C100.799 49.2234 100.524 50.1933 99.9741 51.0564C99.4276 51.9214 98.6315 52.6017 97.5856 53.0975C96.5433 53.5951 95.3093 53.843 93.887 53.843H85.9688V32.6143H93.583C95.0252 32.6143 96.2538 32.8531 97.2671 33.3308C98.284 33.8085 99.0476 34.4527 99.5543 35.2633C100.061 36.074 100.314 36.9896 100.314 38.0065C100.314 39.2659 99.9741 40.3081 99.2937 41.1333C98.6134 41.9548 97.7159 42.5447 96.6012 42.9066ZM88.7409 41.7847H93.4021C94.6995 41.7847 95.7037 41.4807 96.413 40.8727C97.126 40.2611 97.4843 39.4161 97.4843 38.3394C97.4843 37.2646 97.126 36.4214 96.413 35.8134C95.7037 35.2054 94.6778 34.9014 93.3369 34.9014H88.7409V41.7847ZM93.6409 51.5559C95.0252 51.5559 96.1018 51.2338 96.869 50.586C97.6399 49.9346 98.0271 49.0298 98.0271 47.8718C98.0271 46.6956 97.6218 45.7655 96.8111 45.0852C96.0005 44.4048 94.9148 44.0646 93.5541 44.0646H88.7409V51.5559H93.6409Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M103.602 45.4399C103.602 43.7317 103.945 42.2335 104.637 40.9451C105.326 39.6568 106.274 38.658 107.481 37.9487C108.692 37.2357 110.038 36.8774 111.52 36.8774C112.982 36.8774 114.25 37.1941 115.327 37.8256C116.402 38.4535 117.203 39.2442 117.73 40.1996V37.1525H120.538V53.8431H117.73V50.738C117.183 51.7133 116.369 52.5222 115.284 53.1627C114.198 53.7997 112.933 54.1181 111.491 54.1181C110.009 54.1181 108.668 53.7544 107.467 53.0252C106.269 52.2924 105.326 51.2664 104.637 49.9491C103.945 48.6282 103.602 47.1245 103.602 45.4399ZM117.73 45.4688C117.73 44.2094 117.477 43.1147 116.97 42.1828C116.463 41.2473 115.778 40.5308 114.914 40.0332C114.049 39.5374 113.101 39.2877 112.07 39.2877C111.031 39.2877 110.087 39.5319 109.233 40.0187C108.382 40.5073 107.702 41.2184 107.192 42.1539C106.685 43.0858 106.432 44.1805 106.432 45.4399C106.432 46.7192 106.685 47.832 107.192 48.7766C107.702 49.7175 108.382 50.4377 109.233 50.9335C110.087 51.4311 111.031 51.679 112.07 51.679C113.101 51.679 114.049 51.4311 114.914 50.9335C115.778 50.4377 116.463 49.7175 116.97 48.7766C117.477 47.832 117.73 46.7282 117.73 45.4688Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M124.188 45.4688C124.188 43.7426 124.531 42.2335 125.223 40.9451C125.912 39.6568 126.869 38.658 128.096 37.9487C129.326 37.2357 130.732 36.8774 132.316 36.8774C134.366 36.8774 136.058 37.3769 137.389 38.3757C138.721 39.3709 139.599 40.7497 140.024 42.5158H137.042C136.756 41.4988 136.202 40.6954 135.377 40.1055C134.556 39.5175 133.535 39.2225 132.316 39.2225C130.732 39.2225 129.453 39.7654 128.48 40.8511C127.504 41.9367 127.018 43.4766 127.018 45.4688C127.018 47.4774 127.504 49.0299 128.48 50.13C129.453 51.2266 130.732 51.773 132.316 51.773C133.535 51.773 134.552 51.489 135.363 50.919C136.177 50.3508 136.738 49.5365 137.042 48.4798H140.024C139.579 50.1879 138.687 51.5559 137.346 52.5837C136.003 53.6079 134.328 54.1181 132.316 54.1181C130.732 54.1181 129.326 53.7635 128.096 53.0542C126.869 52.3412 125.912 51.3352 125.223 50.036C124.531 48.7386 124.188 47.2168 124.188 45.4688Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M153.026 53.8435L146.475 46.4753V53.8435H143.703V31.3047H146.475V44.5573L152.902 37.1529H156.768L148.914 45.4693L156.796 53.8435H153.026Z"
        fill="var(--secondaryColor)"
      />
      <path
        d="M79.2609 27.7956C78.7977 27.7232 78.3182 27.8879 78.0106 28.2498C76.2228 30.3488 73.6081 31.5539 70.8342 31.5539C65.6519 31.5539 61.4358 27.3378 61.4358 22.1555C61.4358 20.7948 61.7326 19.4485 62.2953 18.2597C62.4907 17.8453 62.4618 17.3586 62.2175 16.9713C61.9732 16.5841 61.5498 16.3453 61.0902 16.3416C55.9422 16.3 51.7551 12.0821 51.7551 6.94322C51.7551 6.37323 51.8148 5.76525 51.9306 5.13736C52.0627 4.42081 51.5995 3.72959 50.8866 3.57759C48.2393 3.01846 45.4618 2.73438 42.6317 2.73438C20.4168 2.73438 2.34375 20.8074 2.34375 43.0224C2.34375 65.2518 20.4168 83.3357 42.6317 83.3357C64.8611 83.3357 82.945 65.2518 82.945 43.0224C82.945 38.0843 82.0512 33.2439 80.2887 28.6388C80.1186 28.1973 79.7278 27.8752 79.2609 27.7956ZM42.6317 80.6486C21.8988 80.6486 5.03082 63.7698 5.03082 43.0224C5.03082 22.2894 21.8988 5.42145 42.6317 5.42145C44.8429 5.42145 47.0161 5.60601 49.1097 5.97153C49.0825 6.30085 49.068 6.62656 49.068 6.94322C49.068 12.9199 53.4705 17.9249 59.2065 18.8695C58.9043 19.928 58.7487 21.039 58.7487 22.1555C58.7487 28.8198 64.1699 34.241 70.8342 34.241C73.6443 34.241 76.3278 33.2783 78.4648 31.5467C79.6554 35.2525 80.258 39.1048 80.258 43.0224C80.258 63.7698 63.3792 80.6486 42.6317 80.6486Z"
        fill="var(--secondaryColor)"
      />
    </svg>
  );
};

export default Logo;
