import React from "react";

const planeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <g clip-path="url(#clip0_1459_527)">
        <path
          d="M55.1246 0.392953C55.8121 0.892507 56.0933 1.55858 55.9683 2.39117L47.9683 50.3484C47.8642 50.952 47.5308 51.4203 46.9683 51.7533C46.6767 51.9199 46.3538 52.0031 45.9996 52.0031C45.7704 52.0031 45.5204 51.9511 45.2496 51.847L28.7808 45.1343L19.4683 55.3439C19.0933 55.781 18.6038 55.9996 17.9996 55.9996C17.7079 55.9996 17.4683 55.9579 17.2808 55.8747C16.885 55.729 16.5725 55.4844 16.3433 55.1409C16.1142 54.7975 15.9996 54.4176 15.9996 54.0013V39.8889L1.24959 33.8631C0.478756 33.5717 0.0620888 32.9993 -0.000411184 32.1459C-0.0629112 31.3341 0.270422 30.72 0.999589 30.3037L52.9996 0.330509C53.7288 -0.106601 54.4371 -0.085786 55.1246 0.392953ZM44.4371 47.1949L51.3433 5.88805L6.53084 31.7087L17.0308 35.9862L43.9996 16.0352L29.0621 40.9193L44.4371 47.1949Z"
          fill="var(--primaryColor)"
        />
      </g>
      <defs>
        <clipPath id="clip0_1459_527">
          <rect width="56" height="56" fill="var(--primaryColor)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default planeIcon;
