import React from "react";

const phoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="56"
      viewBox="0 0 34 56"
      fill="none"
    >
      <path
        d="M11.9727 49.2615H21.806V47.1227H11.9727V49.2615ZM4.71432 55.7782C3.43469 55.7782 2.3671 55.3504 1.51155 54.4949C0.65599 53.6393 0.22636 52.5717 0.222656 51.2921V4.70877C0.222656 3.43099 0.651359 2.36432 1.50877 1.50877C2.36617 0.653212 3.43377 0.224508 4.71155 0.222656H29.0699C30.3477 0.222656 31.4143 0.65136 32.2699 1.50877C33.1254 2.36617 33.5541 3.43377 33.556 4.71154V51.2921C33.556 52.5699 33.1282 53.6375 32.2727 54.4949C31.4171 55.3523 30.3486 55.7801 29.0671 55.7782H4.71432ZM3.00043 43.3838V51.2893C3.00043 51.7171 3.17821 52.1097 3.53377 52.4671C3.88932 52.8245 4.28099 53.0023 4.70877 53.0004H29.0699C29.4958 53.0004 29.8875 52.8227 30.2449 52.4671C30.6023 52.1115 30.7801 51.719 30.7782 51.2893V43.3838H3.00043ZM3.00043 40.606H30.7782V9.94488H3.00043V40.606ZM3.00043 7.1671H30.7782V4.70877C30.7782 4.28284 30.6004 3.89117 30.2449 3.53377C29.8893 3.17636 29.4967 2.99858 29.0671 3.00043H4.71155C4.28377 3.00043 3.89117 3.17821 3.53377 3.53377C3.17636 3.88932 2.99858 4.28192 3.00043 4.71154V7.1671Z"
        fill="var(--primaryColor)"
      />
    </svg>
  );
};

export default phoneIcon;
